<template>
    <div class="first">
        <h1>Een <span>kleine samenvatting,<br/></span>van een <span>grote impact.</span></h1>
        <p>Hendrikje heeft cerebrale parese, een aandoening waarbij kinderen moeite hebben met functioneren als gevolg van een hersenbeschadiging. Behalve dat hij is geboren met deze ernstige aanlegstoornis in de hersenen kreeg hij in 2019 ook nog een ernstige vorm van epilepsie daarbij.</p>
        <div class="images">
            <div style="flex:calc(1487/1080)">
                <img src="../assets/foto_1.png"/>
            </div>
            <div style="flex:calc(1440/1080)" class="moveToBottom">
                <img src="../assets/foto_2.png"/>
            </div>
            <div style="flex:calc(824/1080)">
                <img src="../assets/foto_3.png"/>
            </div>
        </div>
        <!-- <video ref="video" src="/assets/video.mp4"></video> -->
        <!-- <button @click="playVideo" ref="button">Start</button> -->
    </div>
    <div class="second">
        <p>Meer dan eens leek het einde in zicht, en dat kleine lijfje heeft meer tegenslagen kunnen verwerken dan wij als ouders voor mogelijk kunnen houden, én toch doet Hendrik het voor zijn doen geweldig. Dat komt deels door zijn prachtige en positieve karakter, maar minstens zo veel door alle hulp, aandacht, liefde en ondersteuning van mooie en onbaatzuchtige mensen als jullie.</p>
    </div>
    <div class="third">
        <div class="image">
            <img src="../assets/blobs.png" id="blobs"/>
            <img src="../assets/foto_4.png"/>
            <h2>Woorden schieten tekort voor jullie betrokkenheid, en kunnen wij als ouders enkel zeggen; Dankjewel.</h2>
        </div>
    </div>
    <div class="fourth">
        <p>Hendrikje kan er op deze mooie avond zelf niet bij zijn, daarvoor zijn de prikkels helaas te overweldigend. Hij heeft wel zijn best gedaan om voor jullie toch iets speciaals te doen...</p>
        <p>Jullie hebben allemaal een biljet van Hendrikje ontvangen als bedankje voor jullie komst en bijdrage. Het biljet bevat ook nog een speciale boodschap in augmented reality van Hendrikje zelf.</p>
    </div>
    <div class="fifth">
        <h2>Hoe werkt het?</h2>
        <div class="half">
            <div class="icon">
                <img src="../assets/question_icon.png"/>
            </div>
            <h3>Stap 1</h3>
            <a @click="playVideo">Start ervaring</a>
        </div>
        <div class="half">
            <div class="icon">
                <img src="../assets/scan_icon.png"/>
            </div>
            <h3>Stap 2</h3>
            <p>Kijk met de camera naar het biljet</p>
        </div>
    </div>
    <div class="sixt" >
        <h2>Momenten<br/> met<br/> Hendrikje</h2>
        <div class="imageContainer" ref="imageContainer" @mouseenter="dragToScroll" @mousedown="setStartPos">
            <img src="../assets/foto_1.png" @mouseup="openModal">
            <img src="../assets/foto_2.png" @mouseup="openModal">
            <img src="../assets/foto_3.png" @mouseup="openModal">
            <img src="../assets/foto_4.png" @mouseup="openModal">
            <img src="../assets/foto_1.png" @mouseup="openModal">
            <img src="../assets/foto_2.png" @mouseup="openModal">
            <img src="../assets/foto_3.png" @mouseup="openModal">
            <img src="../assets/foto_4.png" @mouseup="openModal">
        </div>
        <div class="modalContainer" @click.stop="openModal" ref="modal">
            <div class="modal">
                <img src="../assets/foto_1.png"/>
                <!-- <div class="close" @click.stop="openModal">&#10540;</div> -->
                <div class="next" @click="nextImg">Volgende &#10132;</div>
            </div>
        </div>
    </div>
    <div class="seventh">
        <img @click="playVideo" src="../assets/play_hendrikje.png"/>
        <h2>Speciale dank aan:</h2>
        <p>Het zijn zoals altijd teveel mensen om op te noemen, en zoals altijd worden er dan ook mensen vergeten. We willen die mensen natuurlijk stuk voor stuk, en jullie allemaal die vanavond aanwezig zijn, uit de grond van ons hart bedanken. Toch verdiend de organisator van deze avond, Marcel Klok van Royal Johan Enschedé, wel een bijzondere vermelding. Wat een ongelooflijke inspanning, en wat een warm gevoel. Heel, heel erg bedankt voor al je inzet, en die van jouw collega’s.</p>
    </div>
    <div class="footer">
        <img @click="playVideo" id="biljet" src="../assets/biljet.png">
        <div onclick="window.open('https://www.wijdoendingen.nl/', '_blank');" class="brandMark">Designed & Developed by <img src="../assets/wdd_100hg_licht.png"/></div>
    </div>
</template>

<script>

export default {
    name: 'Homepage',
    emits: ['continue'],
    data(){
        return {
            startpos: 0,
        }
    },
    mounted() {
        this.$refs.video.addEventListener('ended', () => {
            this.$emit('continue');
        });
    },
    methods: {
        playVideo() {
            // this.$refs.video.play();
            // this.$refs.button.style.visibility = 'collapse';
            this.$emit('continue');
        },
        dragToScroll(){
            const slider = this.$refs.imageContainer
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener("mousedown", (e) => {
                isDown = true;
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
                e.preventDefault;
            });

            slider.addEventListener("mouseleave", () => {
                isDown = false;
            });

            slider.addEventListener("mouseup", (e) => {
                isDown = false;
                e.preventDefault;
            });

            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = x - startX;
                slider.scrollLeft = scrollLeft - walk;
                e.preventDefault;
            });
        },
        setStartPos(event){
            this.startpos = event.clientX;
        },
        openModal(event){
            console.log(this.startpos)
            if(event.target.parentElement.getAttribute('class') != 'modal'){
                let modal = this.$refs.modal

                if(modal.getAttribute('active') == 'true'){
                    modal.setAttribute('active', 'false')
                    setTimeout(()=>{
                        modal.style.display = 'none';
                    },300)
                }else{
                    if(Math.abs(event.clientX - this.startpos) < 10){
                        modal.style.display = 'flex';
                        modal.querySelector('img').src = event.target.src
                        setTimeout(()=>{
                            modal.setAttribute('active', 'true')
                        },0)
                    }
                }
            }
        },
        nextImg(event){
            let modal = this.$refs.modal
            let images = document.querySelectorAll(`.imageContainer img`)
            let index;
            for(let i=0; i < images.length; i++){
                if(images[i].src == modal.querySelector('img').src){
                    if(images.length >= i + 2){
                        index = i + 1;
                    }else{
                        index = 0;
                    }
                }
            }
            
            modal.querySelector('img').src = images[index].src
        }
    }
}
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:wght@500&display=swap');

    *{
        box-sizing: border-box;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
    }

    body, html{
        margin:0;
        padding:0;

        --green:#00cdb9;
        --red:#ff545d;

        --contentWidth: 700px;
        --pagePadding:10vw;
        --topPadding:3rem;

        --padding: 0 max(var(--pagePadding), 50vw - var(--contentWidth) / 2) var(--topPadding) max(var(--pagePadding), 50vw - var(--contentWidth) / 2);
    }

    p{
        float: left;
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        color: black;
        text-align: left;
        padding:0;
        margin:0;
        line-height: 1.4rem;
    }


.first{
    float: left;
    width: 100%;
    background: url('../assets/VectorSmartObject.png');
    background-position: top center;
    background-size: 100% 100%;
    padding: var(--padding);
    padding-top: calc(var(--topPadding)*1.5);

    h1{
        padding:0 10vw var(--topPadding) 0;
        margin:0;
        box-sizing: border-box;
        text-align: left;
        font-family: 'Abril Fatface', cursive;
        color: black;
        font-weight: 400;
        font-size: 2.5rem;
        
        span{
            &:nth-child(1){
                color: var(--green);
            }
            &:nth-child(2){
                color: white;
            }
        }
    }

    p{
        font-weight: 600;
        margin-bottom: var(--topPadding);
    }

    .images{
        float:left;
        width: calc(100% + var(--pagePadding));
        display: flex;  
        flex-direction: row;
        margin: 0 calc(var(--pagePadding)/-2);

        div{
            &:not(:last-child){
                margin-right:1rem;
            }
        }

        img{
            width: 100%;
            height: auto;
            vertical-align: middle;
        }
    }
}

.second{
    padding: var(--padding);
    float: left;
    width: 100%;

    p{
        float: left;
        width: 100%;
    }
}

.third{
    padding: var(--padding);
    float: left;
    width: 100%;

    .image{
        float:left;
        width: 100%;
        position: relative;

        h2{
            width: 80%;
            position: absolute;
            bottom: 2rem;
            left:2rem;
            text-align: left;
            color: white;
            font-family: 'Abril Fatface', cursive;
            font-weight: 400;
            padding: 0;
            margin: 0;
            font-size: 2rem;
            text-shadow: 2px 2px 12px rgba(0,0,0,0.7);
        }

        img{
            float: left;
            width: 100%;
            height: auto;
        }

        #blobs{
            position: absolute;
            top:10%;
            left: -7.5%;
            width: 115%;
            height: auto;
            z-index: -1;
        }
    }
}

.fourth{
    padding: var(--padding);
    float: left;
    width: 100%;

    p{
        font-weight: 600;

        &:nth-child(2){
            font-size: 1.2rem;
            font-weight: 700;
            margin-top: var(--topPadding);
        }
    }
}

.fifth{
    padding: var(--padding);
    float: left;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 3rem;

    h2{
        width: 100%;
        font-size: 2rem;
        font-family: 'Abril Fatface', cursive;
        font-weight: 400;
        margin-bottom: 3rem;
    }

    &:before{
        content: '';
        position: absolute;
        top:50%;
        left:0;
        right:0;
        bottom:0;
        width: 100%;
        height: 50%;
        background-color: #f9f9f9;
        z-index: -1;
    }

    .half{
        max-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width:30vw;
        text-align: center;

        .icon{
            width:70%;
            aspect-ratio: 1/1;
            border-radius: 500px;
            background-color: var(--red);
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 50%;
            }
        }

        a{
            color: white;
            background-color: var(--green);
            border-radius: 1000px;
            font-weight: 600;
            padding: 1rem 2rem;
            text-decoration: none;
            cursor: pointer;
        }

        p{
            margin:0;
            padding: 0;
            width: 70%;
            text-align: center;
        }
    }
}

.sixt{
    float: left;
    width: 100%;
    background-color: var(--green);
    padding:var(--topPadding) 0;

    h2{
        text-align: center;
        float: left;
        font-weight: 700;
        line-height: 2.3rem;
        font-size: 2.3rem;
        width: 100%;
        margin:0;
        padding:var(--padding);
    }

    .modalContainer{
        position: fixed;
        z-index: 10;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        background-color: rgba(0,0,0,0);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overscroll-behavior: none;
        display: none;
        transition: 0.3s;

        .modal{
            width: 80vw;
            max-width: 700px;
            height: auto;
            background-color: white;
            padding: 1rem;
            border-radius: 2rem;
            position: relative;
            transition: 0.3s ease-in-out;

            transform-origin: center;
            transform: scale(0);

           .close{
                top:0;
                right: 0;
                position: absolute;
                width: 3rem;
                height: 3rem;
                transform: translate(50%, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background-color: var(--red);
                border-radius: 1000px;
                text-align: center;
                line-height: 3rem;
                align-items: middle;
           }

           .next{
              cursor: pointer;
              margin-top:1rem;
              font-weight: 600;
           }


           img{
            width: 100%;
            border-radius: 1rem;
            max-height: 70vh;
            object-fit: cover;
           }
        }

        &[active='true']{
            background-color: rgba(0,0,0,0.50);
            .modal{
                transform-origin: center;
                transform: scale(1);
            }
        }
    }

    .imageContainer{
        float:left;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        height: 50vh;
        overflow-y: hidden;
        display: flex;
        padding:0 5vw;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        img{
            float:left;
            height: 100%;
            width: auto;
            margin-right:1rem;
            border-radius: 1rem;
            cursor: grab;

            &:active{
                cursor: grabbing;
            }
        }
    }
}

.seventh{
    float: left;
    width: 100%;
    padding: var(--padding);
    margin-top:-1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        float:left;
        width: 100%;
        font-size: 1.8rem;
        font-weight: 600;
        padding:0;
        margin:0;
        text-align: left;
        margin-bottom:calc(var(--topPadding)/2);
    }

    img{
        float:left;
        width: 100%;
        margin-bottom: var(--topPadding);
        max-width: 500px;
        cursor: pointer;
    }
}

.footer{
    float:left;
    width: 100%;
    padding: var(--padding);
    background-color: var(--red);
    margin-top:15em;

    img#biljet{
        bottom:50%;
        width: 100%;
        left:0;
        transform: translateY(-50%);
        box-shadow: 0 19px 3vw rgba(0,0,0,0.30), 0 15px 3vw rgba(0,0,0,0.22);
    }

    .brandMark{
        color: white;
        line-height: 2rem;
        display: flex;
        justify-content: center;
        cursor: pointer;

        img{
            height:2rem;
            margin-left: 1ch;
        }
    }

}

@media only screen and (max-width: 700px) {
    .first{
        .images{
            flex-wrap: wrap;
            flex-direction: row-reverse !important;

            div{
                flex:unset !important;
                margin: 0 !important;
                padding: 0.5rem !important;

                &:nth-child(2){
                    width: 63.1%;
                }
                &:nth-child(3){
                    width: 36.9%;
                }
            }

            div:first-child{
                width: 100%;
            }
        }

        h1{
            line-height: 2.5rem;
        }
    }

    .third .image h2{
        font-size: 1.1rem;
    }

    .fifth{
        h2{
            margin-bottom: var(--topPadding);
        }

        &:before{
            top:25%;
            height: 75%;
        }

        flex-direction: column;
        align-items: center;

        .half{
            max-width: 200px;
            width:50vw;

            &:first-of-type{
                margin-bottom: var(--topPadding);
            }
        }
    }

    .sixt .imageContainer{
        height: 30vh;
    }

    .footer{
        margin-top:10em;
    }
}



button {
    position: absolute;
}
</style>
