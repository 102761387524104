import {createApp} from 'vue'
import App from './App.vue'
import {chromaKeyShader} from '@/scripts/chroma-key';

AFRAME.registerShader('chromakey', chromaKeyShader);

AFRAME.registerComponent('keepvisibleonlost', {
    init() {
        let el = this.el
        el.sceneEl.addEventListener('xrimagelost', function () {
            el.object3D.visible = true
        });
        el.sceneEl.addEventListener('xrimagefound', function () {
            document.getElementById('tutorial').style.visibility = 'collapse';
            let video = document.getElementById('alpha-video');
            video.play();
            video.muted = false;
            video.volume = 1;
        });
    },
});
createApp(App).mount('#app')
