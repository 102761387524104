<template>
    <Homepage v-if="!startAr" @continue="startAr = true"/>
    <Ar v-else/>
</template>

<script>
import Ar from './components/Ar.vue'
import Homepage from "@/components/Homepage.vue";

export default {
    name: 'App',
    components: {
        Homepage,
        Ar
    },
    data() {
        return {
            startAr: false,
        }
    },
    mounted() {
        document.querySelector('body').style.padding = 0;
        document.querySelector('body').style.margin = 0;
    }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #000000;
    padding: 0;
    margin: 0;
}
</style>
